import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {ISettingOption} from 'app/blocks/model/setting-option.model';

export interface ISettingMetaData extends IBaseEntity {
    id?: number;
    key?: string;
    valueType?: string;
    maxValue?: number;
    minValue?: number;
    maxLength?: number;
    minLength?: number;
    defaultValue?: string;
    settingPattern?: string;
    sequence?: number;
    mandatory?: boolean;
    group?: string;
    subGroup?: string;
    parentSetting?: ISettingMetaData;
    defaultSettingOption?: ISettingOption;
    settingOptions?: ISettingOption[];
    enabled?: boolean;
}

export class SettingMetaData extends BaseEntity implements ISettingMetaData {
    public id: number;
    public key: string;
    public valueType: string;
    public maxValue: number;
    public minValue: number;
    public maxLength: number;
    public minLength: number;
    public defaultValue: string;
    public settingPattern: string;
    public sequence: number;
    public mandatory: boolean;
    public group: string;
    public subGroup: string;
    public parentSetting?: ISettingMetaData;
    public defaultSettingOption?: ISettingOption;
    public settingOptions?: ISettingOption[];
    public enabled?: boolean;

    constructor(settingMetaData?) {
        super();
        if (settingMetaData) {
            this.id = settingMetaData.id;
            this.key = settingMetaData.key;
            this.valueType = settingMetaData.valueType;
            this.maxValue = settingMetaData.maxValue;
            this.minValue = settingMetaData.minValue;
            this.maxLength = settingMetaData.maxLength;
            this.minLength = settingMetaData.minLength;
            this.defaultValue = settingMetaData.defaultValue;
            this.settingPattern = settingMetaData.settingPattern;
            this.sequence = settingMetaData.sequence;
            this.mandatory = settingMetaData.mandatory;
            this.group = settingMetaData.group;
            this.subGroup = settingMetaData.subGroup;
            this.parentSetting = settingMetaData.parentSetting;
            this.defaultSettingOption = settingMetaData.defaultSettingOption;
            this.settingOptions = settingMetaData.settingOptions;
            this.enabled = settingMetaData.enabled;
        }
    }

    get discriminator(): any {
        return this.key;
    }

    clone(): SettingMetaData {
        return new SettingMetaData(this);
    }
}
