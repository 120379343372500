import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Resource} from 'app/constants/resource';

import EntityCrudService from 'app/blocks/service/api/entity-service';
import {SERVER_API_URL} from 'app/app.constants';
import {SettingMetaData, ISettingMetaData} from 'app/blocks/model/setting-meta-data.model';

@Injectable({
    providedIn: 'root'
})
export class SettingMetaDataService extends EntityCrudService<ISettingMetaData> {
    private _actualResourcesUrl: string = null;

    constructor(http: HttpClient) {
        super(http, Resource.SETTING_META_DATA, (obj?) => new SettingMetaData(obj));
        this._actualResourcesUrl = SERVER_API_URL + 'api/resources';
    }

    getSettingMetaData = (resourceName: string): Promise<ISettingMetaData[]> => {
        return this.http
            .get<ISettingMetaData[]>(`${this._resourceUrl}/${resourceName}`)
            .toPromise()
            .then((responseEntities) => responseEntities.map((responseEntity) => this._entityCreator(responseEntity)));
    };

    getResourceSettingMetaData = (resourceName: string): Promise<ISettingMetaData[]> => {
        return this.http
            .get<ISettingMetaData[]>(`${this._actualResourcesUrl}/${resourceName}/setting-meta-data`)
            .toPromise()
            .then((responseEntities) => responseEntities.map((responseEntity) => this._entityCreator(responseEntity)));
    };
}
